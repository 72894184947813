import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Status} from "../../../../enums/core/Status";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {VpnAccount} from "../../../../models/vpn/account/VpnAccount";
import {VpnAccountSaveRequest} from "../../../../models/vpn/account/VpnAccountSaveRequest";


declare var canvasClose: any;
declare var layoutCanvas: any;

// @ts-ignore
export default function VpnAccountDataComponent({vpnAccount, initializeTable}) {

    layoutCanvas();

    const [limit, changeLimit] = useState<number>(0);
    const [password, changePassword] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/account/save/', Rest.initializeRequest(
            new VpnAccountSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new VpnAccount(
                    vpnAccount.id,
                    undefined,
                    undefined,
                    limit,
                    password,
                    Status[status as keyof typeof Status],
                    undefined,
                    username
                )
            ),
            '/vpn/account/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(vpnAccount.id !== undefined) {

            changeLimit(vpnAccount.limit);
            changePassword(vpnAccount.password);
            changeStatus(vpnAccount.status.toString());
            changeUsername(vpnAccount.username);

        } else {

            changeLimit(0);
            changePassword('');
            changeStatus('');
            changeUsername('');

        }

    }, [vpnAccount]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-world-www mr-5"></i>VPN Account</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Speed Limit (Mbps)" type="number" value={limit} onChange={(event) => changeLimit(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="nawala-url-status">Status</InputLabel>
                            <Select labelId="nawala-url-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
