import {AES, enc} from "crypto-ts";
import forge from "node-forge";


export class Encryption {

    public static aesEncrypt(text: string): string {

        return AES.encrypt(text, process.env.REACT_APP_ENCRYPTION_AES_SECRET_KEY as string).toString();

    }


    public static aesDecrypt(text: string): string {

        return AES.decrypt(text, process.env.REACT_APP_ENCRYPTION_AES_SECRET_KEY as string).toString(enc.Utf8);

    }


    public static rsaDecrypt(text: string): string {

        const rsa = forge.pki.privateKeyFromPem(process.env.REACT_APP_ENCRYPTION_RSA_PRIVATE_KEY as string);
        const raw = forge.util.decode64(text);

        return rsa.decrypt(raw);

    }


    public static rsaEncrypt(text: string): string {

        const rsa = forge.pki.publicKeyFromPem(process.env.REACT_APP_ENCRYPTION_RSA_PUBLIC_KEY as string);
        const raw = rsa.encrypt(text);

        return window.btoa(raw);

    }

}
