import {Country} from "../../../enums/core/Country";
import {Status} from "../../../enums/core/Status";
import {VpnServerProvider} from "../../../enums/vpn/server/VpnServerProvider";
import {Base} from "../../core/Base";
import {Timestamp} from "../../core/Timestamp";


export class VpnServer extends Base {

    public hostname: string | undefined;

    public identity: string | undefined;

    public ip: string | undefined;

    public location: Country | undefined;

    public name: string | undefined;

    public password: string | undefined;

    public provider: VpnServerProvider | undefined;

    public status: Status | undefined;

    public url: string | undefined;

    public username: string | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, hostname: string | undefined, identity: string | undefined, ip: string | undefined, location: Country | undefined, name: string | undefined, password: string | undefined, provider: VpnServerProvider | undefined, status: Status | undefined, url: string | undefined, username: string | undefined) {

        super(id, created, modified);
        this.hostname = hostname;
        this.identity = identity;
        this.ip = ip;
        this.location = location;
        this.name = name;
        this.password = password;
        this.provider = provider;
        this.status = status;
        this.url = url;
        this.username = username;

    }

}
