import {BaseRequest} from "../core/BaseRequest";


export class SecurityCaptchaRequest extends BaseRequest {

    public response: string | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, response: string | undefined) {

        super(timestamp, token);
        this.response = response;

    }

}
