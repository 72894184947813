import {Platform} from "../../../enums/core/Platform";
import {BaseRequest} from "../../core/BaseRequest";


export class LogoutRequest extends BaseRequest {

    public authentication: string | undefined;

    public platform: Platform | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, platform: Platform | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.platform = platform;

    }

}
