import {TableFilterType} from "../../../enums/core/table/TableFilterType";


export class TableFilter {

    public column: string | undefined;

    public name: string | undefined;

    public options: any[] | undefined;

    public regex: boolean | undefined;

    public type: TableFilterType | undefined;

    public value: any;


    constructor(column: string | undefined, name: string | undefined, options: any[] | undefined, regex: boolean | undefined, type: TableFilterType | undefined, value: any) {

        this.column = column;
        this.name = name;
        this.options = options;
        this.regex = regex;
        this.type = type;
        this.value = value;

    }

}
