export class BaseRequest {

    public timestamp: string | undefined;

    public token: string | undefined;


    constructor(timestamp: string | undefined, token: string | undefined) {

        this.timestamp = timestamp;
        this.token = token;

    }

}
