export class UserChangePasswordPassword {

    public confirm: string | undefined;

    public current: string | undefined;

    public value: string | undefined;


    constructor(confirm: string | undefined, current: string | undefined, value: string | undefined) {

        this.confirm = confirm;
        this.current = current;
        this.value = value;

    }

}
