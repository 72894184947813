import {BaseRequest} from "../../core/BaseRequest";
import {VpnAccount} from "./VpnAccount";


export class VpnAccountSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: VpnAccount | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: VpnAccount | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
