import {BaseRequest} from "../../core/BaseRequest";
import {VpnServer} from "./VpnServer";


export class VpnServerSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: VpnServer | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: VpnServer | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
