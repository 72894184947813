export class UserPassword {

    public main: string | undefined;

    public recovery: string | undefined;


    constructor(main: string | undefined, recovery: string | undefined) {

        this.main = main;
        this.recovery = recovery;

    }

}
