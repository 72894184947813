import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DateRangePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import React, {useState} from "react";
import {TableFilterType} from "../../enums/core/table/TableFilterType";
import {Core} from "../../libraries/Core";
import {TableFilter} from "../../models/core/table/TableFilter";


declare var filterClose: any;
declare var filterOpen: any;

// @ts-ignore
export default function TableFilterComponent({filters, changeFilters, initializeTable}) {

    const filter = (event: any) => {

        initializeTable();

        filterClose();

        event.preventDefault();

    }

    const setValue = (index: number, value: any) => {

        if(filters[index].type === TableFilterType.DateRange) {

            let dateRange: Date[] = [];

            value.forEach((item: any) => {

                if(item !== null) {

                    dateRange.push(item.$d);

                }

            });

        } else {

            filters[index].value = value;

        }

        changeFilters(Core.renderFilter(filters));

    }

    const toggle = (event: any) => {

        // @ts-ignore
        if(document.getElementsByClassName('table-filter-wrapper')[0].style.display === 'block') {

            filterClose();

        } else {

            filterOpen();

        }

        event.preventDefault();

    }

    const [date, changeDate] = useState<Date>();

    return (
        <div className="table-filter">
            <button onClick={(event) => toggle(event)}>
                <i className="ti ti-filter mr-5"></i>Filter
            </button>
            <div className="table-filter-wrapper">
                {
                    filters.map((filter: TableFilter, index: number) => {

                        if(filter.type === TableFilterType.Boolean) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            )

                        } else if(filter.type === TableFilterType.DateRange) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateRangePicker localeText={{
                                            start: 'Date Start',
                                            end: 'Date End'
                                        }} onChange={(value) => setValue(index, value)}></DateRangePicker>
                                    </LocalizationProvider>
                                </div>
                            )

                        } else if(filter.type === TableFilterType.Enum) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            )

                        } else if(filter.type === TableFilterType.ObjectId) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            )

                        } else {

                            return (
                                <div key={index} className="table-filter-item">
                                    <TextField label={filter.name} type="text" value={filters[index].value} onChange={(event) => setValue(index, event.target.value)} variant="outlined"/>
                                </div>
                            )

                        }

                    })
                }
                <div className="table-filter-button">
                    <button onClick={(event) => filter(event)}>Apply</button>
                </div>
            </div>
        </div>
    );

}
