import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {UserChangePasswordPassword} from "../../../models/user/UserChangePasswordPassword";
import {UserChangePasswordRequest} from "../../../models/user/UserChangePasswordRequest";
import AccountNavigationComponent from "./AccountNavigationComponent";


export default function AccountSecurityComponent() {

    // @ts-ignore
    const [account, changeAccount] = useOutletContext();

    const [password, changePassword] = useState<string>('');
    const [passwordConfirm, changePasswordConfirm] = useState<string>('');
    const [passwordCurrent, changePasswordCurrent] = useState<string>('');

    const save = (event: any): void => {

        if(password === passwordConfirm) {

            fetch(process.env.REACT_APP_API_URL + '/user/change-password/', Rest.initializeRequest(
                new UserChangePasswordRequest(
                    undefined,
                    undefined,
                    Core.initializeAuthentication(),
                    new UserChangePasswordPassword(
                        passwordConfirm,
                        passwordCurrent,
                        password
                    )
                ),
                '/user/change-password/'
            )).then(response => response.json()).then(data => {

                if(data.result) {

                    toast.success(data.response);

                } else {

                    toast.error(data.response);

                }

            }).catch(error => {

                toast.error(error);

            });

        } else {

            toast.error('Confirm password doesn\'t match');

        }

        event.preventDefault();

    }

    useEffect(() => {

        changePassword('');
        changePasswordConfirm('');

    }, [account]);

    return (
        <div className="content">
            <div className="edit-boxed">
                <AccountNavigationComponent></AccountNavigationComponent>
                <div className="edit-boxed-content">
                    <div className="edit-boxed-content-wrapper">
                        <h5><i className="ti ti-user-shield mr-5"></i>Security</h5>
                        <div className="edit-boxed-content-form">
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="New Password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Confirm Password" type="password" value={passwordConfirm} onChange={(event) => changePasswordConfirm(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="Current Password" type="password" value={passwordCurrent} onChange={(event) => changePasswordCurrent(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                        </div>
                        <div className="edit-boxed-content-button">
                            <button onClick={(event) => save(event)}>
                                <i className="ti ti-cloud-upload mr-5"></i>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
