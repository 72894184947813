import {BaseRequest} from "../../core/BaseRequest";


export class InitializeAccountRequest extends BaseRequest {

    public authentication: string | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined) {

        super(timestamp, token);
        this.authentication = authentication;

    }

}
