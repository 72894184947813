import {BaseRequest} from "../core/BaseRequest";
import {User} from "./User";


export class UserSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: User | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: User | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
