import {TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Link, NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {AuthenticationForm} from "../../../enums/authentication/AuthenticationForm";
import {AuthenticationStep} from "../../../enums/authentication/AuthenticationStep";
import {Platform} from "../../../enums/core/Platform";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {SecurityCaptchaRequest} from "../../../models/security/SecurityCaptchaRequest";
import {LoginRequest} from "../../../models/user/log/LoginRequest";
import {UserCheckUsernameRequest} from "../../../models/user/UserCheckUsernameRequest";


declare var hidePreloader: any;
declare var layout: any;

export default function AuthenticationComponent() {

    const location: any = useLocation();
    const navigate: NavigateFunction = useNavigate();

    const captcha = useRef(null);

    const [form, changeForm] = useState<AuthenticationForm>(AuthenticationForm.Login);
    const [step, changeStep] = useState<AuthenticationStep>(AuthenticationStep.Username);

    const [name, changeName] = useState<string>('');
    const [password, changePassword] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const checkUsername = (event: any) => {

        if(username !== '') {

            if(captcha !== null) {

                if(captcha.current !== null) {

                    // @ts-ignore
                    captcha.current.executeAsync().then(response => {

                        fetch(process.env.REACT_APP_API_URL + '/security/captcha/', Rest.initializeRequest(
                            new SecurityCaptchaRequest(undefined, undefined, response), '/security/captcha/'
                        )).then(response => response.json()).then(data => {

                            if(data.result) {

                                fetch(process.env.REACT_APP_API_URL + '/user/check-username/', Rest.initializeRequest(
                                    new UserCheckUsernameRequest(undefined, undefined, username), '/user/check-username/'
                                )).then(response => response.json()).then(data => {

                                    if(data.result) {

                                        if(data.data !== null) {

                                            changeName(Core.initializeUserName(data.data.name));
                                            changeStep(AuthenticationStep.Password);

                                            document.getElementsByName('preloode-password')[0].focus();

                                        }

                                    } else {

                                        toast.error(data.response);

                                    }

                                }).catch(error => {

                                    toast.error(error);

                                });

                            } else {

                                toast.error(data.response);

                            }

                        }).catch(error => {

                            toast.error(error);

                        });

                        // @ts-ignore
                        captcha.current.reset();

                    });

                }

            }

        } else {

            toast.error('Please fill in your username');

        }

        event.preventDefault();

    }

    const enterPassword = (event: any) => {

        if(event.key === 'Enter') {

            login(event);

        }

    }

    const enterUsername = (event: any) => {

        if(event.key === 'Enter') {

            checkUsername(event);

        }

    }

    const login = (event: any) => {

        fetch(process.env.REACT_APP_API_URL + '/user/log/login/', Rest.initializeRequest(
            new LoginRequest(undefined, undefined, password, Platform.Desktop, true, username),
            '/user/log/login/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

                localStorage.setItem('authentication', data.authentication);
                localStorage.setItem('aside', data.user.layout.sidebar.toString());
                localStorage.setItem('version', data.user.layout.version.toString());

                navigate('/');

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const register = (event: any) => {

        event.preventDefault();

    }

    useEffect((): any => {

        layout();
        hidePreloader();

        let authentication: string | undefined = Core.initializeAuthentication();

        if(authentication !== undefined) {

            navigate('/');

        }

    }, [location]);

    return (
        <div id="wrapper">
            <ToastContainer position="top-right" autoClose={5000} closeOnClick newestOnTop={true} pauseOnHover={false} theme="dark"></ToastContainer>
            <div id="preloader">
                <div className="cube">
                    <div className="load-cube c1"></div>
                    <div className="load-cube c2"></div>
                    <div className="load-cube c4"></div>
                    <div className="load-cube c3"></div>
                </div>
            </div>
            <div className="authentication-wrapper">
                <div className="authentication-logo">
                    <Link to={'https://www.preloode.com/'}>
                        <img src="/images/logo/logo.png" alt={process.env.REACT_APP_COMPANY_NAME}/>
                    </Link>
                    <Link to={'https://www.preloode.com/'}>
                        <h1>{process.env.REACT_APP_APPLICATION_NAME}</h1>
                    </Link>
                </div>
                <div className="authentication-tab">
                    <button onClick={() => changeForm(AuthenticationForm.Login)}>Login</button>
                    <button onClick={() => changeForm(AuthenticationForm.SignUp)}>Sign Up</button>
                </div>
                {
                    form === AuthenticationForm.Login &&
                    <div className="authentication-content">
                        <h2>Welcome,</h2>
                        <p className="instruction">Sign in to continue!</p>
                        {step === AuthenticationStep.Password && <p>Hi, {name}.</p>}
                        <div className="authentication-field">
                            <TextField label="Username" name="preloode-username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} onKeyDown={(event) => enterUsername(event)} variant="outlined" sx={step === AuthenticationStep.Password ? {'display': 'none'} : {}}/>
                        </div>
                        <div className="authentication-field">
                            <TextField label="Password" name="preloode-password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} onKeyDown={(event) => enterPassword(event)} variant="outlined" sx={step === AuthenticationStep.Username ? {'display': 'none'} : {}}/>
                        </div>
                        <div className="authentication-button">
                            {step === AuthenticationStep.Username &&
                                <button onClick={(event) => checkUsername(event)}>Continue</button>}
                            {step === AuthenticationStep.Password &&
                                <button onClick={(event) => login(event)}>Login</button>}
                        </div>
                    </div>
                }
                {
                    form === AuthenticationForm.SignUp &&
                    <div className="authentication-content">
                        <h2>Create Account,</h2>
                        <p className="instruction">Sign up to get started!</p>
                        <div className="authentication-field">
                            <TextField label="Username" type="text" variant="outlined"/>
                        </div>
                        <div className="authentication-field">
                            <TextField label="Password" type="password" variant="outlined"/>
                        </div>
                        <div className="authentication-field">
                            <TextField label="First Name" type="text" variant="outlined"/>
                        </div>
                        <div className="authentication-field">
                            <TextField label="Last Name" type="text" variant="outlined"/>
                        </div>
                        <div className="authentication-button">
                            <button onClick={(event) => register(event)}>Sign Up</button>
                        </div>
                    </div>
                }
            </div>
            <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!} size="invisible" ref={captcha}></ReCAPTCHA>
            <div className="clear"></div>
        </div>
    );

}
