import {BaseRequest} from "../../core/BaseRequest";
import {NawalaUrl} from "./NawalaUrl";


export class NawalaUrlSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: NawalaUrl | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: NawalaUrl | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
