import {BaseRequest} from "../BaseRequest";
import {TableFilter} from "./TableFilter";
import {TableSort} from "./TableSort";


export class TableRequest extends BaseRequest {

    public authentication: string | undefined;

    public filters: TableFilter[] | undefined;

    public limit: number | undefined;

    public offset: number | undefined;

    public sorts: TableSort[] | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, filters: TableFilter[] | undefined, limit: number | undefined, offset: number | undefined, sorts: TableSort[] | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.filters = filters;
        this.limit = limit;
        this.offset = offset;
        this.sorts = sorts;

    }

}
