export class UserName {

    public first: string | undefined;

    public last: string | undefined;

    public middle: string | undefined;


    constructor(first: string | undefined, last: string | undefined, middle: string | undefined) {

        this.first = first;
        this.last = last;
        this.middle = middle;

    }

}
