import {Status} from "../../../enums/core/Status";
import {Base} from "../../core/Base";
import {Timestamp} from "../../core/Timestamp";
import {UserReference} from "../../user/UserReference";
import {NawalaGroupAlert} from "./NawalaGroupAlert";
import {NawalaGroupQuota} from "./NawalaGroupQuota";


export class NawalaGroup extends Base {

    public alert: NawalaGroupAlert | undefined;

    public interval: number | undefined;

    public key: string | undefined;

    public name: string | undefined;

    public quota: NawalaGroupQuota | undefined;

    public status: Status | undefined;

    public users: UserReference[] | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, alert: NawalaGroupAlert | undefined, interval: number | undefined, key: string | undefined, name: string | undefined, quota: NawalaGroupQuota | undefined, status: Status | undefined, users: UserReference[] | undefined) {

        super(id, created, modified);
        this.alert = alert;
        this.interval = interval;
        this.key = key;
        this.name = name;
        this.quota = quota;
        this.status = status;
        this.users = users;

    }

}
