export class TelegramReference {

    public chatId: number | undefined;

    public token: string | undefined;


    constructor(chatId: number | undefined, token: string | undefined) {

        this.chatId = chatId;
        this.token = token;

    }

}
