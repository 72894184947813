import {User} from "../../user/User";


export class NawalaGroupOption {

    public users: User[] | undefined;


    constructor(users: User[] | undefined) {

        this.users = users;

    }

}
