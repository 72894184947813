import {TablePaginationLimitOption} from "./TablePaginationLimitOption";


export class TablePaginationLimit {

    public options: TablePaginationLimitOption[] | undefined;

    public value: number | undefined;

    public width: string | undefined;


    constructor(options: TablePaginationLimitOption[] | undefined, value: number | undefined, width: string | undefined) {

        this.options = options;
        this.value = value;
        this.width = width;

    }

}
