import {Platform} from "../../../enums/core/Platform";
import {BaseRequest} from "../../core/BaseRequest";


export class LoginRequest extends BaseRequest {

    public password: string | undefined;

    public platform: Platform | undefined;

    public remember: boolean | undefined;

    public username: string | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, password: string | undefined, platform: Platform | undefined, remember: boolean | undefined, username: string | undefined) {

        super(timestamp, token);
        this.password = password;
        this.platform = platform;
        this.remember = remember;
        this.username = username;

    }

}
