import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";


export default function AccountNavigationComponent() {

    const location: any = useLocation();

    const [navigation, changeNavigation] = useState<string[]>(['', '', '', '']);

    useEffect(() => {

        let path: string = location.pathname.replaceAll('/', '');

        Array.from(document.getElementsByClassName('edit-boxed-navigation-button')).forEach((element: any, index: number) => {

            if(element.getAttribute('href').replaceAll('/', '') === path) {

                navigation[index] = 'active';

            } else {

                navigation[index] = '';

            }

        });

        changeNavigation([navigation[0], navigation[1], navigation[2], navigation[3]]);

    }, [location]);

    return (
        <div className="edit-boxed-navigation">
            <div className="edit-boxed-navigation-wrapper">
                <div className="edit-boxed-navigation-item">
                    <Link to={'/account/'} className={'edit-boxed-navigation-button ' + navigation[0]}>
                        <i className="ti ti-user-search mr-5"></i>Profile
                    </Link>
                </div>
                <div className="edit-boxed-navigation-item">
                    <Link to={'/account/security/'} className={'edit-boxed-navigation-button ' + navigation[1]}>
                        <i className="ti ti-user-shield mr-5"></i>Security
                    </Link>
                </div>
                <div className="edit-boxed-navigation-item">
                    <Link to={'/account/address/'} className={'edit-boxed-navigation-button ' + navigation[2]}>
                        <i className="ti ti-map-pins mr-5"></i>Address
                    </Link>
                </div>
                <div className="edit-boxed-navigation-item">
                    <Link to={'/account/contact/'} className={'edit-boxed-navigation-button ' + navigation[3]}>
                        <i className="ti ti-address-book mr-5"></i>Contact
                    </Link>
                </div>
            </div>
        </div>
    );

}
