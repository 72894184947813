export class TablePaginationLimitOption {

    public text: string | undefined;

    public value: number | undefined;


    constructor(text: string | undefined, value: number | undefined) {

        this.text = text;
        this.value = value;

    }

}
