import {TelegramReference} from "../../integration/telegram/TelegramReference";


export class NawalaGroupAlert {

    public url: TelegramReference | undefined;


    constructor(url: TelegramReference | undefined) {

        this.url = url;

    }

}
