import {Timestamp} from "./Timestamp";


export class Base {

    public id: string | undefined;

    public created: Timestamp | undefined;

    public modified: Timestamp | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined) {

        this.id = id;
        this.created = created;
        this.modified = modified;

    }

}
