import {BaseRequest} from "../core/BaseRequest";


export class UserCheckUsernameRequest extends BaseRequest {

    public username: string | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, username: string | undefined) {

        super(timestamp, token);
        this.username = username;

    }

}
