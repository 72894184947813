import {Encryption} from "./Encryption";
import {Time} from "./Time";


export class Rest {

    public static initializeRequest(data: any, path: string): any {

        const date: Date = new Date();
        data.timestamp = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2) + Time.initializeTimezone(date);
        data.token = Encryption.rsaEncrypt(path + '~' + data.timestamp);

        return {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'pld-key': process.env.REACT_APP_API_KEY!
            }),
            body: JSON.stringify(data)
        };

    }

}
