import {NawalaGroup} from "../group/NawalaGroup";


export class NawalaUrlOption {

    public groups: NawalaGroup[] | undefined;


    constructor(groups: NawalaGroup[] | undefined) {

        this.groups = groups;

    }

}
