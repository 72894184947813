import {BaseRequest} from "../../core/BaseRequest";
import {NawalaGroup} from "./NawalaGroup";


export class NawalaGroupSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: NawalaGroup | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: NawalaGroup | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
