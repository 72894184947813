export class NawalaGroupReference {

    public id: string | undefined;

    key: string | undefined;

    name: string | undefined;


    constructor(id: string | undefined, key: string | undefined, name: string | undefined) {

        this.id = id;
        this.key = key;
        this.name = name;

    }

}
