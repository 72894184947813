import {Status} from "../../../enums/core/Status";
import {Base} from "../../core/Base";
import {Timestamp} from "../../core/Timestamp";
import {NawalaGroupReference} from "../group/NawalaGroupReference";


export class NawalaUrl extends Base {

    public blocked: boolean | undefined;

    public group: NawalaGroupReference | undefined;

    public provider: string | undefined;

    public status: Status | undefined;

    public url: string | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, blocked: boolean | undefined, group: NawalaGroupReference | undefined, provider: string | undefined, status: Status | undefined, url: string | undefined) {

        super(id, created, modified);
        this.blocked = blocked;
        this.group = group;
        this.provider = provider;
        this.status = status;
        this.url = url;

    }

}
