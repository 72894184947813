import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import {Gender} from "../../../enums/core/Gender";
import {Language} from "../../../enums/core/Language";
import {Status} from "../../../enums/core/Status";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {User} from "../../../models/user/User";
import {UserName} from "../../../models/user/UserName";
import {UserSaveRequest} from "../../../models/user/UserSaveRequest";
import AccountNavigationComponent from "./AccountNavigationComponent";


export default function AccountProfileComponent() {

    // @ts-ignore
    const [account, changeAccount] = useOutletContext();

    const [avatar, changeAvatar] = useState<string>('');
    const [gender, changeGender] = useState<string>('');
    const [language, changeLanguage] = useState<string>('');
    const [nameFirst, changeNameFirst] = useState<string>('');
    const [nameLast, changeNameLast] = useState<string>('');
    const [nameMiddle, changeNameMiddle] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const inputAvatar = useRef(null);

    const avatarClick = (event: any): void => {

        // @ts-ignore
        inputAvatar.current?.click();

        event.preventDefault();

    }

    const avatarUpload = (event: any): void => {

        let formData: FormData = new FormData();

        Array.from(event.target.files).forEach((file: any): void => {

            formData.append('avatars', file);

        });

        fetch(process.env.REACT_APP_API_URL + '/user/upload-avatar/', {
            method: 'POST',
            body: formData
        }).then(response => response.json()).then(data => {

            if(data.result) {

                changeAvatar(data.filenames[0]);

            }

        });

    }

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/user/change-profile/', Rest.initializeRequest(
            new UserSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new User(
                    account.id,
                    undefined,
                    undefined,
                    undefined,
                    avatar,
                    undefined,
                    Gender[gender as keyof typeof Gender],
                    Language[language as keyof typeof Language],
                    undefined,
                    new UserName(
                        nameFirst,
                        nameLast,
                        nameMiddle
                    ),
                    undefined,
                    Status[status as keyof typeof Status],
                    undefined,
                    username
                )
            ),
            '/user/change-profile/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(account.id !== undefined) {

            if(account.avatar !== null) {

                changeAvatar(account.avatar);

            }

            changeGender(account.gender);
            changeLanguage(account.language);
            changeNameFirst(account.name.first);

            if(account.name.last !== null) {

                changeNameLast(account.name.last);

            }

            if(account.name.middle !== null) {

                changeNameMiddle(account.name.middle);

            }

            changeStatus(account.status);
            changeUsername(account.username);

        } else {

            changeAvatar('');
            changeGender('');
            changeLanguage('');
            changeNameFirst('');
            changeNameLast('');
            changeNameMiddle('');
            changeStatus('');
            changeUsername('');

        }

    }, [account]);

    return (
        <div className="content">
            <div className="edit-boxed">
                <AccountNavigationComponent></AccountNavigationComponent>
                <div className="edit-boxed-content">
                    <div className="edit-boxed-content-wrapper">
                        <h5><i className="ti ti-user-search mr-5"></i>Profile</h5>
                        <div className="edit-boxed-content-avatar">
                            <div className="edit-boxed-content-avatar-preview">
                                <img src="/images/avatar/avatar-male.png" alt={process.env.REACT_APP_COMPANY_NAME + ' Avatar'}/>
                            </div>
                            <div className="edit-boxed-content-avatar-upload">
                                <input ref={inputAvatar} type="file" accept="image/gif, image/jpeg, image/jpg, image/png" hidden onChange={(event) => avatarUpload(event)}/>
                                <button onClick={(event) => avatarClick(event)}>
                                    <i className="ti ti-cloud-upload mr-5"></i>Upload
                                </button>
                            </div>
                            <div className="edit-boxed-content-avatar-delete">
                                <button>
                                    <i className="ti ti-trash mr-5"></i>Remove
                                </button>
                            </div>
                        </div>
                        <div className="edit-boxed-content-form">
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="Username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <FormControl fullWidth>
                                        <InputLabel id="user-gender">Gender</InputLabel>
                                        <Select labelId="user-gender" label="Gender" value={gender} onChange={(event) => changeGender(event.target.value)}>
                                            {
                                                Object.keys(Gender).map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <FormControl fullWidth>
                                        <InputLabel id="user-language">Language</InputLabel>
                                        <Select labelId="user-language" label="Language" value={language} onChange={(event) => changeLanguage(event.target.value)}>
                                            {
                                                Object.keys(Language).map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="First Name" type="text" value={nameFirst} onChange={(event) => changeNameFirst(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Middle Name" type="text" value={nameMiddle} onChange={(event) => changeNameMiddle(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Last Name" type="text" value={nameLast} onChange={(event) => changeNameLast(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                        </div>
                        <div className="edit-boxed-content-button">
                            <button onClick={(event) => save(event)}>
                                <i className="ti ti-cloud-upload mr-5"></i>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
