export class UserReference {

    public id: string | undefined;

    public username: string | undefined;


    constructor(id: string | undefined, username: string | undefined) {

        this.id = id;
        this.username = username;

    }

}
