import {TablePaginationLimit} from "./TablePaginationLimit";


export class TablePagination {

    public current: number | undefined;

    public limit: TablePaginationLimit | undefined;

    public total: number | undefined;


    constructor(current: number | undefined, limit: TablePaginationLimit | undefined, total: number | undefined) {

        this.current = current;
        this.limit = limit;
        this.total = total;

    }

}
