import {Gender} from "../../enums/core/Gender";
import {Language} from "../../enums/core/Language";
import {Status} from "../../enums/core/Status";
import {UserType} from "../../enums/user/UserType";
import {Base} from "../core/Base";
import {Layout} from "../core/Layout";
import {Timestamp} from "../core/Timestamp";
import {UserAddress} from "./UserAddress";
import {UserContact} from "./UserContact";
import {UserName} from "./UserName";
import {UserPassword} from "./UserPassword";


export class User extends Base {

    public address: UserAddress | undefined;

    public avatar: string | undefined;

    public contact: UserContact | undefined;

    public gender: Gender | undefined;

    public language: Language | undefined;

    public layout: Layout | undefined;

    public name: UserName | undefined;

    public password: UserPassword | undefined;

    public status: Status | undefined;

    public type: UserType | undefined;

    public username: string | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, address: UserAddress | undefined, avatar: string | undefined, contact: UserContact | undefined, gender: Gender | undefined, language: Language | undefined, layout: Layout | undefined, name: UserName | undefined, password: UserPassword | undefined, status: Status | undefined, type: UserType | undefined, username: string | undefined) {

        super(id, created, modified);
        this.address = address;
        this.avatar = avatar;
        this.contact = contact;
        this.gender = gender;
        this.language = language;
        this.layout = layout;
        this.name = name;
        this.password = password;
        this.status = status;
        this.type = type;
        this.username = username;

    }

}
