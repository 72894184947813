import {Status} from "../../../enums/core/Status";
import {Base} from "../../core/Base";
import {Timestamp} from "../../core/Timestamp";
import {UserReference} from "../../user/UserReference";


export class VpnAccount extends Base {

    public limit: number | undefined;

    public password: string | undefined;

    public status: Status | undefined;

    public user: UserReference | undefined;

    public username: string | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, limit: number | undefined, password: string | undefined, status: Status | undefined, user: UserReference | undefined, username: string | undefined) {

        super(id, created, modified);
        this.limit = limit;
        this.password = password;
        this.status = status;
        this.user = user;
        this.username = username;

    }

}
