export class NawalaGroupQuota {

    public limit: number | undefined;

    public usage: number | undefined;


    constructor(limit: number | undefined, usage: number | undefined) {

        this.limit = limit;
        this.usage = usage;

    }

}
