import {TablePagination} from "./TablePagination";
import {TableSort} from "./TableSort";


export class Table {

    public columns: string[] | undefined;

    public offset: number | undefined;

    public pagination: TablePagination | undefined;

    public rows: any;

    public sorts: TableSort[] | undefined;

    public total: number | undefined;


    constructor(columns: string[] | undefined, offset: number | undefined, pagination: TablePagination | undefined, rows: any, sorts: TableSort[] | undefined, total: number | undefined) {

        this.columns = columns;
        this.offset = offset;
        this.pagination = pagination;
        this.rows = rows;
        this.sorts = sorts;
        this.total = total;

    }

}
