export class UserContact {

    public discord: string | undefined;

    public email: string | undefined;

    public phone: string | undefined;

    public skype: string | undefined;

    public slack: string | undefined;

    public telegram: string | undefined;

    public wechat: string | undefined;

    public whatsapp: string | undefined;


    constructor(discord: string | undefined, email: string | undefined, phone: string | undefined, skype: string | undefined, slack: string | undefined, telegram: string | undefined, wechat: string | undefined, whatsapp: string | undefined) {

        this.discord = discord;
        this.email = email;
        this.phone = phone;
        this.skype = skype;
        this.slack = slack;
        this.telegram = telegram;
        this.wechat = wechat;
        this.whatsapp = whatsapp;

    }

}
