import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {TableFilterType} from "../../../../enums/core/table/TableFilterType";
import {NawalaUrlNetwork} from "../../../../enums/nawala/url/NawalaUrlNetwork";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {Time} from "../../../../libraries/Time";
import {Table} from "../../../../models/core/table/Table";
import {TableFilter} from "../../../../models/core/table/TableFilter";
import {TableRequest} from "../../../../models/core/table/TableRequest";
import {TableSort} from "../../../../models/core/table/TableSort";
import {NawalaReport} from "../../../../models/nawala/report/NawalaReport";
import TableFilterComponent from "../../TableFilterComponent";
import TablePaginationComponent from "../../TablePaginationComponent";


export default function NawalaReportComponent() {

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('created.timestamp', 'Date Time', [], false, TableFilterType.DateRange, ''),
        new TableFilter('network', 'Network', Object.keys(NawalaUrlNetwork), false, TableFilterType.Enum, ''),
        new TableFilter('url', 'URL', [], false, TableFilterType.String, ''),
        new TableFilter('blocked', 'Status', [{'name': 'Online', 'value': false}, {
            'name': 'Blocked',
            'value': true
        }], false, TableFilterType.Boolean, '')
    ]);
    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('created.timestamp', -1)])
    );

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/report/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, Core.initializeAuthentication(), filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/nawala/report/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters} initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Date Time</th>
                            <th>URL</th>
                            <th>Provider</th>
                            <th>Group</th>
                            <th>Network</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={8}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: NawalaReport, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{Time.toDateTimeString(row.created!.timestamp!)}</td>
                                        <td>{row.url}</td>
                                        <td>{row.provider}</td>
                                        <td>{row.group!.name}</td>
                                        <td>{row.network}</td>
                                        <td>
                                            {
                                                row.blocked &&
                                                <span className="danger">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>Blocked
                                                </span>
                                            }
                                            {
                                                !row.blocked &&
                                                <span className="success">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>Online
                                                </span>
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                )

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable} initializeTable={initializeTable}></TablePaginationComponent>
            </div>
        </div>
    );

}
