export class TableSort {

    public column: string | undefined;

    public direction: number | undefined;


    constructor(column: string | undefined, direction: number | undefined) {

        this.column = column;
        this.direction = direction;

    }

}
