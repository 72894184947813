import {Country} from "../../enums/core/Country";


export class UserAddress {

    public city: string | undefined;

    public country: Country | undefined;

    public district: string | undefined;

    public state: string | undefined;

    public street: string | undefined;

    public zip: string | undefined;


    constructor(city: string | undefined, country: Country | undefined, district: string | undefined, state: string | undefined, street: string | undefined, zip: string | undefined) {

        this.city = city;
        this.country = country;
        this.district = district;
        this.state = state;
        this.street = street;
        this.zip = zip;

    }

}
