import {BaseRequest} from "../core/BaseRequest";
import {UserChangePasswordPassword} from "./UserChangePasswordPassword";


export class UserChangePasswordRequest extends BaseRequest {

    public authentication: string | undefined;

    public password: UserChangePasswordPassword | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, password: UserChangePasswordPassword | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.password = password;

    }

}
